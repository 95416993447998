
import { computed, defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useHead } from "@vueuse/head";

import CModal from "@/components/Modal.vue";
import CFormSelect, { CFormSelectOption } from "@/components/form/Select.vue";
import CFormInputText from "@/components/form/InputText.vue";
import PProgressBar from "primevue/progressbar";

import { CashierForm } from "@/store/cashier/types";
import { isEditFormByRouteName } from "@/libs/utils";

const VCashierForm = defineComponent({
  name: "VCashierForm",
  components: { CModal, CFormInputText, PProgressBar, CFormSelect },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const _id = Number(route.params._id);
    const isEditForm = isEditFormByRouteName(route.name);

    useHead({ title: `${isEditForm ? "Editar" : "Cadastrar"} caixa | obmed` });

    const cashier = computed(() => store.state.cashier.current);
    const units = computed(() => store.state.unit.list);
    const unitsOptions = computed<CFormSelectOption[]>(() =>
      units.value.map((item) => ({ label: item.nm_unidade, value: item.id }))
    );

    const form = reactive<CashierForm>({
      nm_caixa: "",
      cd_unidade_medclub: null,
    });
    const loading = reactive({ cashier: false, submit: false });

    function handleClose() {
      router.back();
    }

    async function onSubmit() {
      if (!form.cd_unidade_medclub)
        return store.commit("addToast", { summary: 'O campo "Unidade" é obrigatório', severity: "warn" });

      loading.submit = true;
      const response = isEditForm
        ? await store.dispatch("updateCashier", { _id, form })
        : await store.dispatch("createCashier", { form });
      loading.submit = false;

      if (response?.status === 200 || response?.status === 201) {
        store.dispatch("getCashiers");
        router.replace({ name: "cashier-list" });
      }
    }

    async function getCashier() {
      loading.cashier = true;
      await Promise.allSettled([store.dispatch("getCashier", { _id }), store.dispatch("getUnits")]);
      loading.cashier = false;
    }

    async function handleApplyForm() {
      await getCashier();

      form.nm_caixa = cashier.value?.nm_caixa || "";
      form.cd_unidade_medclub = cashier.value?.cd_unidade_medclub?.id || null;
    }

    if (isEditForm) handleApplyForm();
    else Promise.allSettled([store.dispatch("getUnits")]);

    return { form, units, unitsOptions, loading, isEditForm, handleClose, onSubmit };
  },
});

export default VCashierForm;
